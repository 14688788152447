import React from 'react';
import type { FC } from 'react';
import { Container } from '@material-ui/core';
import { Footer } from '../common/Footer';

export const Layout: FC = ({ children }) => (
  <>
    <Container>
      {children}
      <Footer />
    </Container>
  </>
);
