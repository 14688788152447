import React from 'react';
import type { FC } from 'react';
import { Grid, Divider, Typography } from '@material-ui/core';
import { Link } from 'gatsby'

export const Footer: FC = () => (
  <footer>
    <Divider />
    <Grid container> 
      <Grid item>
        <Link to="/">Top</Link>
      </Grid>
      <Grid item>
        &nbsp;-&nbsp;
      </Grid>
      <Grid item>
        <Link to="/about/">About</Link>
      </Grid>
    </Grid>
    <Typography>QSL Print.com - &copy; JJ1AYZ Yoshihiro Ohsuka</Typography>
  </footer>
);
